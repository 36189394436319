import { Link, useParams } from 'react-router-dom'
import './razdelMore.scss'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRazdelMore } from 'store/slices/razdelSlice'
import { AiOutlineEye } from 'react-icons/ai'
import Layout from 'components/layout'
import Error from 'components/error'
import SwiperGallery from 'components/swiperGallery'
import Loader from 'components/loader'

const RazdelMore = () => {
    const { lang } = useSelector(state => state.lang)
    const { razdelMore, razdelLoadingStatus } = useSelector(state => state.razdel)
    const { query } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchRazdelMore({ lang, query }))
    }, [lang, query])

    if (razdelLoadingStatus === 'loading') return <Loader />
    if (razdelLoadingStatus === 'ERR_NETWORK') return <Error />

    return razdelMore && (
        <>
            {
                razdelMore.razdel_more.not_found ? <h1 className='container'>{razdelMore.razdel_more.not_found}</h1> :

                    <Layout title={razdelMore.razdel_more[1].tema} href={<Link to={`/razdel/${razdelMore.razdel_more[0].r_url}`}>{razdelMore.words[0].tema} &#62; </Link>}>
                        <div className='razdelMore'>
                            <div className="razdelMore__date">
                                <div>
                                    {razdelMore.razdel_more[1].day}.{razdelMore.razdel_more[1].month}.{razdelMore.razdel_more[1].year}
                                </div>
                                <div>
                                    <AiOutlineEye />
                                    {razdelMore.razdel_more[1].views}
                                </div>
                            </div>

                            {razdelMore.photo && <SwiperGallery data={razdelMore.photo} />}

                            <div className="razdelMore__text" dangerouslySetInnerHTML={{ __html: razdelMore.razdel_more[1].text }}></div>
                        </div>
                    </Layout>
            }
        </>
    )
}

export default RazdelMore