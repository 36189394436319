import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "api";

const initialState = {
    receptionLoadingStatus: 'idle',
    reception: null,
    words: null,
    submitLoading: 'idle',
    msg: null
}

export const fetchReception = createAsyncThunk(
    'reception/fetchReception',
    async (lang) => {
        const res = await axios.get(`${API_URL}/feedback/feedback.php/?lang=${lang}`)
        return res.data[0]
    }
)

export const fetchSubmit = createAsyncThunk(
    'reception/fetchSubmit',
    async ({ name, phone, text, email, lang, captchaResponse, userFile }) => {
        const formData = new FormData();
        formData.append('name', name);
        formData.append('phone', phone);
        formData.append('text', text);
        formData.append('email', email);
        formData.append('captcha', captchaResponse);
        formData.append('userfile[]', userFile);

        const response = await axios.post(`${API_URL}/feedback/?lang=${lang}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response.data[0].feedback[0];
    }
);

const receptionSlice = createSlice({
    name: 'reception',
    initialState,
    reducers: {
        changeReceptionPage: (state, action) => {
            state.page = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReception.pending, state => { state.receptionLoadingStatus = 'loading' })
            .addCase(fetchReception.fulfilled, (state, action) => {
                state.reception = action.payload.feedback
                state.words = action.payload.words[0]
                state.receptionLoadingStatus = 'finish'
            })
            .addCase(fetchReception.rejected, (state, action) => {
                state.receptionLoadingStatus = action.error.code
            })

            .addCase(fetchSubmit.pending, state => { state.submitLoading = 'loading' })
            .addCase(fetchSubmit.fulfilled, (state, action) => {
                state.msg = action.payload.message
                state.submitLoading = action.payload.result
            })
            .addCase(fetchSubmit.rejected, (state, action) => {
                state.submitLoading = action.error.code
            })

            .addDefaultCase(() => { })
    }
})

const { actions, reducer } = receptionSlice

export const {
    changeReceptionPage
} = actions

export default reducer