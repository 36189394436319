import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "api";

const initialState = {
    newsLoadingStatus: 'idle',
    news: [],
    newsMore: null,
    pagination: [],
    page: 1,
}

export const fetchNews = createAsyncThunk(
    'news/fetchNews',
    async ({ lang, page }) => {
        const res = await axios.get(`${API_URL}/pages/news.php/?lang=${lang}&page=${page}&kol=12`)
        return res.data[0]
    }
)

export const fetchNewsMore = createAsyncThunk(
    'news/fetchNewsMore',
    async ({ lang, param }) => {
        const res = await axios.get(`${API_URL}/pages/news_more.php/?lang=${lang}&url=${param}`)
        return res.data[0]
    }
)

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {
        changeNewsPage: (state, action) => {
            state.page = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNews.pending, state => { state.newsLoadingStatus = 'loading' })
            .addCase(fetchNews.fulfilled, (state, action) => {
                state.news = action.payload.news
                state.pagination = action.payload.pagination
                state.newsLoadingStatus = 'finish'
            })
            .addCase(fetchNews.rejected, (state, action) => {
                state.newsLoadingStatus = action.error.code
            })

            .addCase(fetchNewsMore.pending, state => { state.newsLoadingStatus = 'loading' })
            .addCase(fetchNewsMore.fulfilled, (state, action) => {
                state.newsMore = action.payload
                state.newsLoadingStatus = 'finish'
            })
            .addCase(fetchNewsMore.rejected, (state, action) => {
                state.newsLoadingStatus = action.error.code
            })
            .addDefaultCase(() => { })
    }
})

const { actions, reducer } = newsSlice

export const {
    changeNewsPage
} = actions

export default reducer