import { AiOutlineEye } from 'react-icons/ai'
import './newsItem.scss'
import { Link } from 'react-router-dom'

const NewsItem = ({ item }) => {
    return (
        <Link to={`/news/${item.n_url}`} className="newsItem">
            <div className="newsItem__img">
                <img src={item.image} alt="" />
            </div>
            <div className="newsItem__content">
                <div className="newsItem__date">
                    <div>
                        {item.day + '.' + item.month + '.' + item.year}
                    </div>
                    <div>
                        <AiOutlineEye />{item.views}
                    </div>
                </div>
                <div className="newsItem__name">
                    <span dangerouslySetInnerHTML={{ __html: item.tema }}></span>
                </div>
            </div>
        </Link>
    )
}

export default NewsItem