import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from 'api';
import axios from "axios";

const initialState = {
    documentsLoadingStatus: 'idle',
    documents: [],
    words: null,
    documentMore: null,
    pagination: [],
    page: 1,
}


export const fetchDocuments = createAsyncThunk(
    'documents/fetchDocuments',
    async ({ lang, page }) => {
        const res = await axios.get(`${API_URL}/pages/documents.php/?lang=${lang}&page=${page}&kol=10`)
        return res.data[0]
    }
)

export const fetchDocumentMore = createAsyncThunk(
    'documents/fetchDocumentMore',
    async ({ lang, id }) => {
        const res = await axios.get(`${API_URL}/pages/document_more.php/?lang=${lang}&id=${id}`)
        return res.data[0]
    }
)

const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        changeDocumentsPage: (state, action) => {
            state.page = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchDocuments.pending, state => { state.documentsLoadingStatus = 'loading' })
            .addCase(fetchDocuments.fulfilled, (state, action) => {
                state.documentsLoadingStatus = 'finish'
                state.documents = action.payload.docs
                state.pagination = action.payload.pagination
                state.words = action.payload.words[0]
            })
            .addCase(fetchDocuments.rejected, (state, action) => {
                state.documentsLoadingStatus = action.error.code
            })

            .addCase(fetchDocumentMore.pending, state => { state.documentsLoadingStatus = 'loading' })
            .addCase(fetchDocumentMore.fulfilled, (state, action) => {
                state.documentsLoadingStatus = 'finish'
                state.documentMore = action.payload
            })
            .addCase(fetchDocumentMore.rejected, (state, action) => {
                state.documentsLoadingStatus = action.error.code
            })
            .addDefaultCase(() => { })
    }
})

const { actions, reducer } = documentsSlice

export const {
    changeDocumentsPage
} = actions

export default reducer