import { useDispatch, useSelector } from 'react-redux'
import './faq.scss'
import { useEffect, useState } from 'react'
import { fetchFaq } from 'store/slices/faqSlice'
import Layout from 'components/layout'
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai'

const Faq = () => {
    const { lang } = useSelector(state => state.lang)
    const { faq } = useSelector(state => state.faq)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchFaq(lang))
    }, [lang])


    return faq && (
        <Layout title={faq.tema}>
            <div className='faq'>
                {
                    faq.faq.map((item, i) => <Item item={item} key={i} />)
                }
            </div>
        </Layout>
    )
}

const Item = ({ item }) => {
    const [active, setActive] = useState(false)

    return (
        <div className='faq__item'>
            <div onClick={() => setActive(!active)} className="faq__question">
                <div>
                    {item.title}
                </div>

                <div>
                    {active ?
                        <AiOutlineUp />
                        :
                        <AiOutlineDown />
                    }
                </div>
            </div>
            {active &&
                <div className="faq__answer">
                    {item.text}
                </div>
            }
        </div>
    )
}

export default Faq