
import { useParams } from 'react-router-dom'
import './otherPages.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { fetchOtherPage } from 'store/slices/otherPage'
import Layout from 'components/layout'
import Error from 'components/error'
import Loader from 'components/loader'
import Grid from 'components/grid'
import FullScreen from 'components/fullScreen'

const OtherPages = () => {
    const [slider, setSlider] = useState(false)
    const [activeSlide, setActiveSlide] = useState(0)
    const { lang } = useSelector(state => state.lang)
    const { otherPage, otherPageLoadingStatus } = useSelector(state => state.otherPage)
    const { url } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchOtherPage({ lang, url }))
    }, [lang, url])

    if (otherPageLoadingStatus === 'loading') {
        return <Loader />
    }

    if (otherPageLoadingStatus === 'ERR_NETWORK') {
        return <Error />
    }

    return otherPage && (
        <Layout title={otherPage.page[0].tema}>
            <div className="otherPage">
                {otherPage.page[0].photo_location === 'top' &&
                    <Grid>
                        {otherPage.photo &&
                            otherPage.photo.map((item, i) => <img onClick={() => {
                                setActiveSlide(i)
                                setSlider(true)
                            }} src={item.image} key={i} className="otherPage__photo" alt="" />)}
                    </Grid>}
                <div className="otherPage__text" dangerouslySetInnerHTML={{ __html: otherPage.page[0].text }}></div>
                {otherPage.page[0].photo_location === 'bottom' &&
                    <Grid>
                        {otherPage.photo &&
                            otherPage.photo.map((item, i) => <img onClick={() => {
                                setActiveSlide(i)
                                setSlider(true)
                            }} src={item.image} key={i} className="otherPage__photo" alt="" />)}
                    </Grid>}
                {slider &&
                    <FullScreen data={otherPage.photo} activeSlide={activeSlide} setSlider={setSlider} />
                }
            </div>
        </Layout>
    )
}

export default OtherPages