import { useSelector } from 'react-redux'
import './search.scss'
import { AiOutlineSearch } from 'react-icons/ai'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useState } from 'react'

const Search = () => {
    const { general } = useSelector(state => state.lang)
    const [text, setText] = useState('')
    const navigate = useNavigate()

    function handleSubmit(event) {
        event.preventDefault();
        navigate(`/result/${text}`)
    }

    return general && (
        <form onSubmit={handleSubmit} className='search'>
            <button className='search__btn' type='submit'>
                <AiOutlineSearch />
            </button>
            <input type="text" value={text} onChange={(e) => setText(e.target.value)} className="search__input" name='search' placeholder={general.words[2].search} />
        </form>
    )
}

export default Search