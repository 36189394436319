import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "api";

const initialState = {
    faqLoadingStatus: 'idle',
    faq: null,
    pagination: [],
    words: [],
    page: 1,
}

export const fetchFaq = createAsyncThunk(
    'faq/fetchFaq',
    async (lang) => {
        const res = await axios.get(`${API_URL}/pages/faq.php/?lang=${lang}`)
        return res.data[0]
    }
)

const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {
        changeFaqPage: (state, action) => {
            state.page = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchFaq.pending, state => { state.faqLoadingStatus = 'loading' })
            .addCase(fetchFaq.fulfilled, (state, action) => {
                state.faq = action.payload
                state.faqLoadingStatus = 'finish'
            })
            .addCase(fetchFaq.rejected, (state, action) => {
                state.faqLoadingStatus = action.error.code
            })

            .addDefaultCase(() => { })
    }
})

const { actions, reducer } = faqSlice

export const {
    changeFaqPage
} = actions

export default reducer