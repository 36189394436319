import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "api";

const initialState = {
    personalLoadingStatus: 'idle',
    personal: [],
    personalMore: null,
    pagination: [],
    words: [],
    wordsMore: '',
    page: 1,
}

export const fetchPersonal = createAsyncThunk(
    'personal/fetchPersonal',
    async ({ lang, page }) => {
        const res = await axios.get(`${API_URL}/pages/personal.php/?lang=${lang}&kol=10&page=${page}`)
        return res.data[0]
    }
)

export const fetchPersonalMore = createAsyncThunk(
    'personal/fetchPersonalMore',
    async ({ lang, param }) => {
        const res = await axios.get(`${API_URL}/pages/personal_more.php/?lang=${lang}&url=${param}`)
        return res.data[0]
    }
)

const personalSlice = createSlice({
    name: 'personal',
    initialState,
    reducers: {
        changePersonalPage: (state, action) => {
            state.page = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPersonal.pending, state => { state.personalLoadingStatus = 'loading' })
            .addCase(fetchPersonal.fulfilled, (state, action) => {
                state.personal = action.payload.personal
                state.pagination = action.payload.pagination
                state.words = action.payload.words
                state.personalLoadingStatus = 'finish'
            })
            .addCase(fetchPersonal.rejected, (state, action) => {
                state.personalLoadingStatus = action.error.code
            })

            .addCase(fetchPersonalMore.pending, state => { state.personalLoadingStatus = 'loading' })
            .addCase(fetchPersonalMore.fulfilled, (state, action) => {
                state.personalMore = action.payload.personal[0]
                state.wordsMore = action.payload.words[0].tema
                state.personalLoadingStatus = 'finish'
            })
            .addCase(fetchPersonalMore.rejected, (state, action) => {
                state.personalLoadingStatus = action.error.code
            })
            .addDefaultCase(() => { })
    }
})

const { actions, reducer } = personalSlice

export const {
    changePersonalPage
} = actions

export default reducer