import { useDispatch, useSelector } from 'react-redux'
import './result.scss'
import { Link, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { changeSearchPage, fetchSearch } from 'store/slices/searchSlice'
import Layout from 'components/layout'
import Loader from 'components/loader'
import Grid from 'components/grid'
import Error from 'components/error'

const Result = () => {
    const { lang } = useSelector(state => state.lang)
    const { search, page, words, pagination, searchLoadingStatus } = useSelector(state => state.search)
    const dispatch = useDispatch()
    const { query } = useParams()

    useEffect(() => {
        dispatch(fetchSearch({ lang, page, query }))
    }, [lang, page, query])

    const onChange = (i) => dispatch(changeSearchPage(i))

    const linkFilter = ({ pages, r_url, n_url }) => {
        let link = ''
        if (pages) {
            link += '/' + pages
        }
        if (r_url) {
            link += '/' + r_url
        }
        if (n_url) {
            link += '/' + n_url
        }
        return link
    }

    if (searchLoadingStatus === 'ERR_NETWORK') return <Error />

    return search && (
        <Layout title={words} onChange={onChange} pagination={pagination} page={page}>
            <div className='result'>
                {
                    searchLoadingStatus === 'loading' ?
                        <Loader />
                        :
                        search.there_not ?
                            <h2>{search.there_not}</h2>
                            :
                            search.map((item, i) => {
                                const link = linkFilter(item)

                                return (
                                    <Link to={link} key={i} className="result__item">
                                        <div className="result__item-img">
                                            <img src={item.image} alt={item.title} />
                                        </div>
                                        <div className="result__item-content">
                                            {item.date && <div className="result__item-date">
                                                {item.date}
                                            </div>}
                                            <h3 className="result__item-title">
                                                {item.title}
                                            </h3>
                                            <div className="result__item-text" dangerouslySetInnerHTML={{ __html: item.small_text }}></div>
                                        </div>
                                    </Link>
                                )
                            })
                }
            </div>
        </Layout>
    )
}

export default Result