import './page.scss'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPage } from 'store/slices/pageSlice'
import Layout from 'components/layout'
import Grid from 'components/grid'
import Loader from 'components/loader'
import Error from 'components/error'
import { useParams } from 'react-router-dom'
import FullScreen from 'components/fullScreen'

const Page = () => {
    const [slider, setSlider] = useState(false)
    const [activeSlide, setActiveSlide] = useState(0)
    const { lang } = useSelector(state => state.lang)
    const { page, pageLoadingStatus } = useSelector(state => state.page)
    const { url } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchPage({ lang, url }))
    }, [lang, url])

    if (pageLoadingStatus === 'loading') {
        return <Loader />
    }

    if (pageLoadingStatus === 'ERR_NETWORK') {
        return <Error />
    }

    return page && (
        <Layout title={page.page[0].tema}>
            <div className="page">
                {page.page[0].photo_location === 'top' &&
                    <Grid>
                        {page.photo &&
                            page.photo.map((item, i) => <img onClick={() => {
                                setActiveSlide(i)
                                setSlider(true)
                            }} src={item.image} key={i} className="page__photo" alt="" />)}
                    </Grid>
                }
                <div className="page__text" dangerouslySetInnerHTML={{ __html: page.page[0].text }}></div>

                {page.page[0].photo_location !== 'top' &&
                    <Grid>
                        {page.photo &&
                            page.photo.map((item, i) => <img onClick={() => {
                                setActiveSlide(i)
                                setSlider(true)
                            }} src={item.image} key={i} className="page__photo" alt="" />)}
                    </Grid>
                }


                {slider &&
                    <FullScreen data={page.photo} activeSlide={activeSlide} setSlider={setSlider} />
                }
            </div>
        </Layout>
    )
}

export default Page