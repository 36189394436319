import { Link, useParams } from 'react-router-dom'
import './personalMore.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { fetchPersonalMore } from 'store/slices/personalSlice'
import Layout from 'components/layout'
import Loader from 'components/loader'
import Error from 'components/error'

const PersonalMore = () => {
    const { lang, general } = useSelector(state => state.lang)
    const { personalMore, wordsMore, personalLoadingStatus } = useSelector(state => state.personal)
    const { param } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchPersonalMore({ lang, param }))
    }, [lang, param])

    if (personalLoadingStatus === 'loading') return <Loader />
    if (personalLoadingStatus === 'ERR_NETWORK') return <Error />

    return personalMore && (
        <Layout title={personalMore.fio} href={<Link to='/personal'> {wordsMore} &#62; </Link>}>
            <div className='personalMore'>
                <div className="personalMore__top">
                    <div className="personalMore__img">
                        <img src={personalMore.image} alt={personalMore.fio} />
                    </div>
                    <div className="personalMore__title">
                        <h2>
                            {personalMore.doljnost}
                        </h2>
                        {personalMore.email &&
                            <div>
                                {general.words[2].email}: {personalMore.email}
                            </div>
                        }
                        {personalMore.phone &&
                            <div>
                                {general.words[2].telephone}: {personalMore.phone}
                            </div>
                        }
                    </div>
                </div>
                <div className="personal__text" dangerouslySetInnerHTML={{ __html: personalMore.text }}>

                </div>
            </div>
        </Layout>
    )
}

export default PersonalMore