import { useDispatch, useSelector } from 'react-redux'
import './razdel.scss'
import { useEffect } from 'react'
import { changeRazdelPage, fetchRazdel } from 'store/slices/razdelSlice'
import Grid from 'components/grid'
import { Link, useParams } from 'react-router-dom'
import { AiOutlineEye } from 'react-icons/ai'
import Layout from 'components/layout'
import Loader from 'components/loader'
import Error from 'components/error'

const Razdel = () => {
    const { lang } = useSelector(state => state.lang)
    const { razdel, pagination, page, words, razdelLoadingStatus } = useSelector(state => state.razdel)
    const { param } = useParams()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchRazdel({ lang, param, page }))
    }, [lang, param, page])

    const onChange = (i) => dispatch(changeRazdelPage(i))

    if (razdelLoadingStatus === 'ERR_NETWORK') return <Error />

    return razdel.length > 0 && (
        <Layout title={words} page={page} onChange={onChange} pagination={pagination}>
            <div className='razdel'>
                <Grid>
                    {razdelLoadingStatus === 'loading' ?
                        <Loader />
                        :
                        razdel.map((item, i) => {
                            return (
                                <Link key={i} className='razdel__item' to={`r_url=${item.r_url}&n_url=${item.n_url}`}>
                                    <div className="razdel__item-img">
                                        <img src={item.image} alt={item.tema} />
                                    </div>
                                    <div className="razdel__item-date">
                                        <div>
                                            {`${item.day}.${item.month}.${item.year}`}
                                        </div>
                                        <div>
                                            <AiOutlineEye />
                                            {item.views}
                                        </div>
                                    </div>
                                    <div className="razdel__item-title">
                                        {item.tema}
                                    </div>
                                    <div className="razdel__item-text" dangerouslySetInnerHTML={{ __html: item.small_text }}></div>

                                </Link>
                            )
                        })}
                </Grid>
            </div>
        </Layout>
    )
}

export default Razdel