import { configureStore } from '@reduxjs/toolkit'
import lang from './slices/langSlice'
import news from './slices/newsSlice'
import page from './slices/pageSlice'
import documents from './slices/documentsSlice'
import razdel from './slices/razdelSlice'
import gallery from './slices/gallerySlice'
import personal from './slices/personalSlice'
import search from './slices/searchSlice'
import faq from './slices/faqSlice'
import reception from './slices/receptionSlice'
import otherPage from './slices/otherPage'

const stringMiddleware = (store) => (next) => (action) => {
    if (typeof action === 'string') {
        return next({ type: action })
    }
    return next(action)
}

const store = configureStore({
    reducer: {
        lang,
        news,
        page,
        documents,
        razdel,
        gallery,
        personal,
        search,
        faq,
        reception,
        otherPage
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(stringMiddleware),
    devTools: process.env.NODE_ENV !== 'production'
})

export default store

