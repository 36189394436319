import Ticker from 'components/ticker'
import './newsDoc.scss'
import { useSelector } from 'react-redux'
import Grid from 'components/grid'
import NewsItem from 'components/newsItem'
import { Link } from 'react-router-dom'

const NewsDoc = () => {
    const { main } = useSelector(state => state.lang)

    return main && (
        <section className={main.vacancy.length < 1 ? 'newsDoc full' : 'newsDoc'}>
            <div className="newsDoc__news">

                <h2 className="newsDoc__news__title">
                    {main.words[0].latest_news}
                </h2>
                <Grid>
                    {main.news.map((item, i) => (
                        <NewsItem key={i} item={item} />
                    ))}
                </Grid>
            </div>
            {main.vacancy.length > 0 &&
                <div className="newsDoc__docs">
                    <h2 className="newsDoc__docs__title">
                        {main.vacancy[0].r_title}
                    </h2>
                    <div className="newsDoc__docs__items">
                        {main.vacancy.map((item, i) => {
                            if (i === 0) {
                                return null
                            } else {
                                return (
                                    <Link
                                        to={`/razdel/${main.vacancy[0].r_url}/r_url=${main.vacancy[0].r_url}&n_url=${item.n_url}`}
                                        className="newsDoc__docs__item" key={i}>
                                        <div className="newsDoc__docs__item-date">
                                            {item.day + '.' + item.month + '.' + item.year}
                                        </div>
                                        <div className="newsDoc__docs__item-title">
                                            {item.tema}
                                        </div>
                                    </Link>
                                )
                            }
                        })}
                    </div>
                </div>
            }
        </section>
    )
}

export default NewsDoc