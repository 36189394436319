import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "api";

const initialState = {
    galleryLoadingStatus: 'idle',
    gallery: [],
    pagination: [],
    words: [],
    page: 1,
}

export const fetchGallery = createAsyncThunk(
    'gallery/fetchGallery',
    async ({ lang, page }) => {
        const res = await axios.get(`${API_URL}/pages/gallery.php/?lang=${lang}&kol=12&page=${page}`)
        return res.data[0]
    }
)

const gallerySlice = createSlice({
    name: 'gallery',
    initialState,
    reducers: {
        changeGalleryPage: (state, action) => {
            state.page = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGallery.pending, state => { state.galleryLoadingStatus = 'loading' })
            .addCase(fetchGallery.fulfilled, (state, action) => {
                state.gallery = action.payload.gallery
                state.pagination = action.payload.pagination
                state.words = action.payload.words[0].tema
                state.galleryLoadingStatus = 'finish'
            })
            .addCase(fetchGallery.rejected, (state, action) => {
                state.galleryLoadingStatus = action.error.code
            })
            .addDefaultCase(() => { })
    }
})

const { actions, reducer } = gallerySlice

export const {
    changeGalleryPage
} = actions

export default reducer