import React, { useEffect } from 'react';

const Tracking = () => {

    useEffect(() => {
        const java1 = {
            refer: encodeURIComponent(document.referrer),
            page: encodeURIComponent(window.location.href),
            c: document.cookie ? 'yes' : 'now',
            java: navigator.javaEnabled ? 'yes' : 'now',
            resolution: `${window.innerWidth}x${window.innerHeight}`,
            colorDepth: window.screen.colorDepth || window.screen.pixelDepth,
        };

        const link = document.createElement('a');
        link.href = 'https://www.net.kg/stat.php?id=7151&fromsite=7151';
        link.target = '_blank';

        const image = document.createElement('img');
        image.src = `https://www.net.kg/img.php?id=7151&${Object.entries(java1)
            .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
            .join('&')}`;
        image.style.border = '0';
        image.alt = 'WWW.NET.KG';
        image.width = '88';
        image.height = '31';

        link.appendChild(image);

        const noscript = document.createElement('noscript');
        const noscriptLink = document.createElement('a');
        noscriptLink.href = 'https://www.net.kg/stat.php?id=7151&fromsite=7151';
        noscriptLink.target = '_blank';

        const noscriptImage = document.createElement('img');
        noscriptImage.src = 'https://www.net.kg/img.php?id=7151';
        noscriptImage.style.border = '0';
        noscriptImage.alt = 'WWW.NET.KG';
        noscriptImage.width = '88';
        noscriptImage.height = '31';

        noscriptLink.appendChild(noscriptImage);
        noscript.appendChild(noscriptLink);

        const container = document.getElementById('custom-script-container');

        container.appendChild(link);
        container.appendChild(noscript);
    }, []);

    return <div id="custom-script-container"></div>;
};

export default Tracking;
