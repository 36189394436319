import { useSelector } from 'react-redux'
import './projects.scss'
import { BsBriefcaseFill } from 'react-icons/bs'
import Grid from 'components/grid'
import { Link } from 'react-router-dom'

const Projects = () => {
    const { main } = useSelector(state => state.lang)

    return (main && main.project.length > 0) && (
        <section className='projects'>
            <h2 className="projects__title">
                {main.project[0].r_title}
            </h2>

            <Grid>
                {main.project.map((item, i) => {
                    if (i === 0) return null

                    return (
                        <Link to={`/razdel/${main.project[0].r_url}/r_url=${main.project[0].r_url}&n_url=${item.n_url}`} key={i} className="projects__item">
                            <h3>
                                {item.title}
                            </h3>
                            <BsBriefcaseFill />
                        </Link>
                    )
                })}
            </Grid>
        </section>
    )
}

export default Projects