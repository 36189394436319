import { useSelector } from 'react-redux'
import './error.scss'

const Error = () => {
    const { lang } = useSelector(state => state.lang)
    return (
        <h2 className='error'>
            {
                lang === 'ky' ?
                    <>
                        Байланыш көйгөйү
                    </>
                    :
                    <>
                        Проблема с подключением
                    </>
            }
        </h2>
    )
}

export default Error