import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "api";

const initialState = {
    searchLoadingStatus: 'idle',
    search: [],
    searchMore: null,
    pagination: [],
    words: '',
    wordsMore: '',
    page: 1,
}

export const fetchSearch = createAsyncThunk(
    'search/fetchSearch',
    async ({ lang, page, query }) => {
        const res = await axios.get(`${API_URL}/search_result/?lang=${lang}&kol=10&page=${page}&q=${query}`)
        return res.data[0]
    }
)

export const fetchSearchMore = createAsyncThunk(
    'search/fetchSearchMore',
    async ({ lang, param }) => {
        const res = await axios.get(`${API_URL}/pages/search_more.php/?lang=${lang}&url=${param}`)
        return res.data[0]
    }
)

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        changeSearchPage: (state, action) => {
            state.page = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSearch.pending, state => { state.searchLoadingStatus = 'loading' })
            .addCase(fetchSearch.fulfilled, (state, action) => {
                state.search = action.payload.result
                state.pagination = action.payload.pagination
                state.words = action.payload.words[0].tema
                state.searchLoadingStatus = 'finish'
            })
            .addCase(fetchSearch.rejected, (state, action) => {
                state.searchLoadingStatus = action.error.code
            })

            .addCase(fetchSearchMore.pending, state => { state.searchLoadingStatus = 'loading' })
            .addCase(fetchSearchMore.fulfilled, (state, action) => {
                state.searchMore = action.payload.search[0]
                state.wordsMore = action.payload.words[0].tema
                state.searchLoadingStatus = 'finish'
            })
            .addCase(fetchSearchMore.rejected, (state, action) => {
                state.searchLoadingStatus = action.error.code
            })
            .addDefaultCase(() => { })
    }
})

const { actions, reducer } = searchSlice

export const {
    changeSearchPage
} = actions

export default reducer