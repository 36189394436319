import { useDispatch, useSelector } from 'react-redux'
import './documents.scss'
import { useEffect } from 'react'
import { changeDocumentsPage, fetchDocuments } from 'store/slices/documentsSlice'
import Layout from 'components/layout'
import Loader from 'components/loader'
import Error from 'components/error'

const Documents = () => {
    const { lang, general } = useSelector(state => state.lang)
    const { documents, words, documentsLoadingStatus, pagination, page } = useSelector(state => state.documents)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchDocuments({ lang, page }))
    }, [lang, page])

    const onChange = (i) => dispatch(changeDocumentsPage(i))

    if (documentsLoadingStatus === 'ERR_NETWORK') return <Error />

    return (words && general) && (
        <Layout title={general.words[0].documents} onChange={onChange} page={page} pagination={pagination}>
            <div className='documents'>
                <div className="documents__items title">
                    <div>
                        {words.number}
                    </div>
                    <div>
                        {words.documents_name}
                    </div>
                    <div>
                        {words.publication_date}
                    </div>
                    <div>
                        {words.acceptance_date}
                    </div>
                    <div>
                        {words.place_of_ac}
                    </div>
                </div>

                {documentsLoadingStatus === 'loading' ?
                    <Loader />
                    :
                    documents.map((item, i) => {
                        return (
                            <div key={i} className="documents__items">
                                <div>{item.number}</div>
                                <a href={item.file_link} className='name' rel='noreferrer' target='_blank'>
                                    {item.title}
                                </a>
                                <div>
                                    {item.day}.
                                    {item.month}.
                                    {item.year}
                                </div>
                                <div>
                                    {item.acc_date_day}.
                                    {item.acc_date_month}.
                                    {item.acc_date_year}
                                </div>
                                <div>
                                    {item.place}
                                </div>
                            </div>
                        )
                    })}
            </div>
        </Layout>
    )
}

export default Documents