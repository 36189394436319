import Layout from 'components/layout'
import './siteMap.scss'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

const SiteMap = () => {
    const { general } = useSelector(state => state.lang)

    return general && (
        <Layout title={general.words[3].site_map}>
            <ul className='siteMap'>
                <li className='siteMap__item'>
                    <Link to='/' >
                        {general.words[0].main}
                    </Link>
                </li>
                <li className='siteMap__item'>
                    <Link to='/documents' >
                        {general.words[0].documents}
                    </Link>
                </li>
                <li className='siteMap__item'>
                    <Link to='/personal' >
                        {general.words[0].employees}
                    </Link>
                </li>
                <li className='siteMap__item'>
                    <Link to='/news' >
                        {general.words[0].news}
                    </Link>
                </li>
                <li className='siteMap__item'>
                    <Link to='/gallery' >
                        {general.words[0].photo_gallery}
                    </Link>
                </li>

                <li className='siteMap__item'>
                    <Link to='/reception' >
                        {general.words[0].internet_reception}
                    </Link>
                </li>

                <li className='siteMap__item'>
                    <Link to='/faq' >
                        {general.words[3].faq}
                    </Link>
                </li>

                <li className='siteMap__item'>
                    <div>
                        {general.words[0].razdely}
                    </div>
                    <ul >
                        {general.razdel.map((item, i) => {
                            return (
                                <li key={i}>
                                    <Link to={'/razdel/' + item.r_url}>
                                        {item.title}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </li>

                <li className="siteMap__item">
                    <div>
                        {general.pages[0].pages_tema}
                    </div>
                    <ul>
                        {general.pages.map((item, i) => {
                            if (i === 0) return null
                            return (
                                <li key={i}>
                                    <Link to={`/${item.n_url}`} >
                                        {item.title}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </li>
                <li className='siteMap__item'>
                    <div>
                        {general.other_pages[0].other_pages}
                    </div>
                    <ul>
                        {general.other_pages.map((item, i) => {
                            if (i === 0) return null
                            return (
                                <li key={i}>
                                    <Link to='/' >
                                        {item.title}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </li>
            </ul>
        </Layout>
    )
}

export default SiteMap