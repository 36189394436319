import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "api";

const initialState = {
    otherPageLoadingStatus: 'idle',
    otherPage: null
}


export const fetchOtherPage = createAsyncThunk(
    'otherPage/fetchOtherPage',
    async ({ lang, url }) => {
        const res = await axios.get(`${API_URL}/pages/page_other.php?lang=${lang}&url=${url}`)
        return res.data[0]
    }
)

const otherPageSlice = createSlice({
    name: 'otherPage',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOtherPage.pending, state => { state.otherPageLoadingStatus = 'loading' })
            .addCase(fetchOtherPage.fulfilled, (state, action) => {
                state.otherPageLoadingStatus = 'finish'
                state.otherPage = action.payload
            })
            .addCase(fetchOtherPage.rejected, (state, action) => {
                state.otherPageLoadingStatus = action.error.code
            })
            .addDefaultCase(() => { })
    }
})

const { actions, reducer } = otherPageSlice

export default reducer