import { useSelector } from 'react-redux'
import { Link, NavLink, useLocation } from 'react-router-dom'

import { GrDown } from 'react-icons/gr'
import './nav.scss'
import { useState } from 'react'
import { useEffect } from 'react'

const navData = [
    { title: 'Главная', class: 'nav__main active', href: '/' },
    { title: 'О нас', class: 'nav__about', href: '/about' },
    { title: 'Проекты', class: 'nav__documents', href: '/documents' },
    { title: 'Сотрудники', class: 'nav__personal', href: '/personal' },
    { title: 'Услуги', class: 'nav__services', href: '/services' },
    { title: 'Разделы', class: 'nav__sections', href: '/sections' },
    { title: 'Вакансии', class: 'nav__jobs', href: '/jobs' }
]

const Nav = ({ burger, setBurger }) => {
    const [razdelActive, setRazdelActive] = useState(false)
    const [pagesActive, setPagesActive] = useState(false)
    const { lang, general } = useSelector(state => state.lang)
    const { pathname } = useLocation()

    return general && (
        <nav className={burger ? 'nav active container' : 'nav container'}>
            <div>
                <NavLink className={({ isActive }) => isActive ? "active" : ""} to='/' onClick={() => setBurger(false)}>
                    {general.words[0].main}
                </NavLink>
            </div>
            <div>
                <div className={pagesActive ? 'nav__razdel show' : 'nav__razdel'} onMouseLeave={() => setPagesActive(false)}>
                    <button type='button' onClick={() => setPagesActive(!pagesActive)} className='nav__razdel-first'>
                        <div>
                            {general.pages[0].pages_tema}
                        </div>
                        <GrDown />
                    </button>
                    <div className="nav__razdel-more">
                        {general.pages.map((item, i) => {
                            return (
                                <Link onClick={() => {
                                    setPagesActive(false)
                                    setBurger(false)
                                }} key={i} to={'/page/' + item.n_url}>
                                    {item.title}
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div>
                <NavLink className={({ isActive }) => isActive ? "active" : ""} to='/documents' onClick={() => setBurger(false)}>
                    {general.words[0].documents}
                </NavLink>
            </div>
            <div>
                <NavLink className={({ isActive }) => isActive ? "active" : ""} to='/personal' onClick={() => setBurger(false)}>
                    {general.words[0].employees}
                </NavLink>
            </div>
            <div>
                <NavLink className={({ isActive }) => isActive ? "active" : ""} to='/news' onClick={() => setBurger(false)}>
                    {general.words[0].news}
                </NavLink>
            </div>
            <div>
                <NavLink className={({ isActive }) => isActive ? "active" : ""} to='/gallery' onClick={() => setBurger(false)}>
                    {general.words[0].photo_gallery}
                </NavLink>
            </div>
            <div>
                <div className={razdelActive ? 'nav__razdel show' : 'nav__razdel'} onMouseLeave={() => setRazdelActive(false)}>
                    <button type='button' onClick={() => setRazdelActive(!razdelActive)} className='nav__razdel-first'>
                        {general.words[0].razdely}
                        <GrDown />
                    </button>
                    <div className="nav__razdel-more">
                        {general.razdel.map((item, i) => {
                            return (
                                <Link onClick={() => {
                                    setRazdelActive(false)
                                    setBurger(false)
                                }} key={i} to={'/razdel/' + item.r_url}>
                                    {item.title}
                                </Link>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div>
                <NavLink className={({ isActive }) => isActive ? "active" : ""} to='/reception' onClick={() => setBurger(false)}>
                    {general.words[0].internet_reception}
                </NavLink>
            </div>

        </nav>
    )
}

export default Nav