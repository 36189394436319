import { Link } from 'react-router-dom';
import './pathname.scss'
import { useSelector } from 'react-redux';

const Pathname = ({ title, href }) => {
    const { general } = useSelector(state => state.lang)

    return general && (
        <>
            <div className='pathname'>
                <Link to='/'>{general.words[0].main}</Link> &#62;
                {href && href}
                <div className='pathname__active'>{title}</div>
            </div>
            <h1>
                {title}
            </h1>
        </>
    )
}

export default Pathname