import Banner from "components/banner/banner"
import Error from "components/error"
import Loader from "components/loader"
import NewsDoc from "components/newsDoc"
import Projects from "components/projects"
import Urls from "components/urls"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchMain } from "store/slices/langSlice"

const Main = () => {
    const { lang, main, langLoadingStatus } = useSelector(state => state.lang)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchMain(lang))
    }, [lang])

    if (langLoadingStatus === 'loading') return <Loader />
    if (langLoadingStatus === 'ERR_NETWORK') return <Error />

    return main && (
        <>
            <Banner />
            <main>
                <div className="container">
                    <NewsDoc />
                    <Projects />
                    <Urls />
                </div>
            </main>
        </>
    )
}

export default Main