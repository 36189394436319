import { useDispatch, useSelector } from 'react-redux'
import './personal.scss'
import { useEffect } from 'react'
import { changePersonalPage, fetchPersonal } from 'store/slices/personalSlice'
import Layout from 'components/layout'
import Grid from 'components/grid'
import { Link } from 'react-router-dom'
import Loader from 'components/loader'
import Error from 'components/error'

const Personal = () => {
    const { lang } = useSelector(state => state.lang)
    const { personal, page, pagination, words, personalLoadingStatus } = useSelector(state => state.personal)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(fetchPersonal({ lang, page }))
    }, [lang, page])

    const onChange = (i) => dispatch(changePersonalPage(i))

    if (personalLoadingStatus === 'ERR_NETWORK') return <Error />

    return personal.length > 0 && (
        <Layout title={words[0].tema} pagination={pagination} page={page} onChange={onChange}>
            <div className="personal">
                <Grid>
                    {personalLoadingStatus === 'loading' ?
                        <Loader />
                        :
                        personal.map((item, i) => {
                            return (
                                <Link to={item.url} key={i} className="personal__item">
                                    <div className="personal__item-img">
                                        <img src={item.image} alt="" />
                                    </div>
                                    <div className="personal__item-content">
                                        <div className="personal__item-name">
                                            {item.fio}
                                        </div>
                                        <div className="personal__item-job">
                                            {item.doljnost}
                                        </div>
                                    </div>
                                </Link>
                            )
                        })}
                </Grid>
            </div>
        </Layout>
    )
}

export default Personal