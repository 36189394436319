import './footer.scss'
import { useSelector } from 'react-redux'
import TopHeader from 'components/topHeader'
import { Link } from 'react-router-dom'
import Tracking from 'components/tracking'

const Footer = () => {
    const { general } = useSelector(state => state.lang)

    return general && (
        <footer className='footer'>
            <TopHeader />

            <div className="footer__bottom container">
                <div className="footer__bottom__other">
                    {general.other_pages.map((item, i) => {
                        if (i === 0) {
                            return (
                                <h3 key={i} className="footer__bottom__title">
                                    {item.other_pages}
                                </h3>
                            )
                        } else {
                            return (
                                <Link to={`/page_other/${item.n_url}`} key={i} className="footer__bottom__text">
                                    {item.title}
                                </Link>
                            )
                        }
                    })}

                    <Link to='/faq' className="footer__bottom__text">
                        {general.words[3].faq}
                    </Link>
                    <Link to='/site-map' className="footer__bottom__text">
                        {general.words[3].site_map}
                    </Link>
                </div>

                <div className="footer__bottom__about">
                    <h3 className="footer__bottom__title">
                        {general.words[0].about_us}
                    </h3>
                    <Link to='/personal' className="footer__bottom__text">
                        {general.words[0].employees}
                    </Link>
                    <Link to='/documents' className="footer__bottom__text">
                        {general.words[0].documents}
                    </Link>
                    <Link to='/gallery' className="footer__bottom__text">
                        {general.words[0].photo_gallery}
                    </Link>
                    <Link to='/news' className="footer__bottom__text">
                        {general.words[0].news}
                    </Link>
                </div>

                <div className="footer__bottom__razdel">
                    <h3 className="footer__bottom__title">
                        {general.words[0].razdely}
                    </h3>
                    {general.razdel.map((item, i) => {
                        return (
                            <Link to={`/razdel/${item.r_url}`} key={i} className="footer__bottom__text">
                                {item.title}
                            </Link>
                        )
                    })}
                    <Tracking />

                </div>

                <div className="footer__bottom__contacts">
                    <h3 className="footer__bottom__title">
                        {general.words[3].contact_details}
                    </h3>
                    <div className="footer__bottom__text">
                        {general.basic_data[0].phone}
                    </div>
                    <div className="footer__bottom__text">
                        {general.basic_data[0].email}
                    </div>
                    <div className="footer__bottom__text">
                        {general.basic_data[0].address}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer