import SwiperGallery from 'components/swiperGallery'
import './fullScreen.scss'

const FullScreen = ({ setSlider, activeSlide, data }) => {
    return (
        <div className="fullScreen">
            <button className='fullScreen__close' onClick={() => setSlider(false)} type="button">
                X
            </button>
            {data.length > 0 && <SwiperGallery data={data} activeSlide={activeSlide} />}
        </div>
    )
}

export default FullScreen