import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "api";

const initialState = {
    pageLoadingStatus: 'idle',
    page: null
}


export const fetchPage = createAsyncThunk(
    'page/fetchPage',
    async ({ lang, url }) => {
        const res = await axios.get(`${API_URL}/pages/page.php/?lang=${lang}&url=${url}`)
        return res.data[0]
    }
)

const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPage.pending, state => { state.pageLoadingStatus = 'loading' })
            .addCase(fetchPage.fulfilled, (state, action) => {
                state.pageLoadingStatus = 'finish'
                state.page = action.payload
            })
            .addCase(fetchPage.rejected, (state, action) => {
                state.pageLoadingStatus = action.error.code
            })
            .addDefaultCase(() => { })
    }
})

const { actions, reducer } = pageSlice

export default reducer