import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Header from './components/header';
import ScrollTop from 'components/scrollTop'
import { Documents, Faq, Gallery, Main, News, NewsMore, OtherPages, Page, Personal, PersonalMore, Razdel, RazdelMore, Reception, Result, SiteMap } from './pages';
import Footer from 'components/footer';



function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <ScrollTop />
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/page/:url' element={<Page />} />
          <Route path='/news' element={<News />} />
          <Route path='/documents' element={<Documents />} />
          <Route path='/razdel/:param' element={<Razdel />} />
          <Route path='/razdel/:param/:query' element={<RazdelMore />} />
          <Route path='/news/:param' element={<NewsMore />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/personal' element={<Personal />} />
          <Route path='/personal/:param' element={<PersonalMore />} />
          <Route path='/result/:query' element={<Result />} />
          <Route path='/faq' element={<Faq />} />
          <Route path='/reception' element={<Reception />} />
          <Route path='/site-map' element={<SiteMap />} />
          <Route path='/page_other/:url' element={<OtherPages />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter >
  );
}

export default App;
