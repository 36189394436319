import './banner.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar } from 'swiper';
import 'swiper/css';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { useSelector } from 'react-redux';
import img1 from './../../assets/img/banner.png'
import img2 from './../../assets/img/banner2.jpg'
import img3 from './../../assets/img/banner3.webp'
import { useMemo } from 'react';

const Banner = () => {
    const { general, main } = useSelector(state => state.lang)
    const cachedImages = useMemo(() => {
        return {
            img1: new Image().src = img1,
            img2: new Image().src = img2,
            img3: new Image().src = img3,
        };
    }, []);

    return (general && main) && (
        <section className="banner">
            <Swiper
                modules={[Navigation, Pagination, Scrollbar]}
                spaceBetween={0}
                slidesPerView={1}
                navigation
                pagination={{ clickable: true }}>
                <SwiperSlide>
                    <div style={{ backgroundImage: `url('${cachedImages.img1}')` }} className='banner__item'>
                        <div className="container">
                            <h1>
                                {general.words[1].welcome_to_the_official_site}
                            </h1>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={{ backgroundImage: `url('${cachedImages.img3}')` }} className='banner__item'>
                        <div className="container">
                            <h1>
                                {general.basic_data[0].title_site}
                            </h1>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div style={{ backgroundImage: `url('${cachedImages.img2}')` }} className='banner__item'>
                        <div className="container">
                            <h1>
                                {general.words[1].government_agency}
                            </h1>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
        </section>
    )
}

export default Banner