import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "api";

const initialState = {
    razdelLoadingStatus: 'idle',
    razdel: [],
    words: '',
    razdelMore: null,
    pagination: 0,
    page: 1,
}


export const fetchRazdel = createAsyncThunk(
    'razdel/fetchRazdel',
    async ({ lang, param, page }) => {
        const res = await axios.get(`${API_URL}/pages/razdel.php/?lang=${lang}&url=${param}&page=${page}&kol=10`)
        return res.data[0]
    }
)
export const fetchRazdelMore = createAsyncThunk(
    'razdel/fetchRazdelMore',
    async ({ lang, query }) => {
        const res = await axios.get(`${API_URL}/pages/razdel_more.php/?lang=${lang}&${query}`)
        return res.data[0]
    }
)

const razdelSlice = createSlice({
    name: 'razdel',
    initialState,
    reducers: {
        changeRazdelPage: (state, action) => {
            state.page = action.payload
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRazdel.pending, state => { state.razdelLoadingStatus = 'loading' })
            .addCase(fetchRazdel.fulfilled, (state, action) => {
                state.razdelLoadingStatus = 'finish'
                state.razdel = action.payload.razdel
                state.pagination = action.payload.pagination
                state.words = action.payload.words[0].tema
            })
            .addCase(fetchRazdel.rejected, (state, action) => {
                state.razdelLoadingStatus = action.error.code
            })

            .addCase(fetchRazdelMore.pending, state => { state.razdelLoadingStatus = 'loading' })
            .addCase(fetchRazdelMore.fulfilled, (state, action) => {
                state.razdelLoadingStatus = 'finish'
                state.razdelMore = action.payload
            })
            .addCase(fetchRazdelMore.rejected, (state, action) => {
                state.razdelLoadingStatus = action.error.code
            })
            .addDefaultCase(() => { })
    }
})

const { actions, reducer } = razdelSlice

export const { changeRazdelPage } = actions

export default reducer